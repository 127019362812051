@charset "utf-8";
/* =================================================================================


  utility.css


================================================================================= */

/*
  Positon
----------------------------------------------- */

.u-pos-s {
  position: static !important;
}
.u-pos-r {
  position: relative !important;
}
.u-pos-a {
  position: absolute !important;
}
.u-pos-sk {
  position: sticky !important;
}
.u-pos-f {
  position: fixed !important;
}

/* ツール
----------------------------------------------- */
.u-hidden {
  visibility: hidden;
}

.u-none {
  display: none;
}

.u-block {
  display: block;
}

.u-clear {
  clear: both;
}

/*
  Letter Spacing
----------------------------------------------- */

.u-lts-nm {
  letter-spacing: normal !important;
}
.u-lts0 {
  letter-spacing: 0 !important;
}
.u-lts1 {
  letter-spacing: 1px !important;
}
.u-lts2 {
  letter-spacing: 2px !important;
}
.u-lts3 {
  letter-spacing: 3px !important;
}
.u-lts4 {
  letter-spacing: 4px !important;
}
.u-lts5 {
  letter-spacing: 5px !important;
}
.u-lts6 {
  letter-spacing: 6px !important;
}
.u-lts001em {
  letter-spacing: 0.01em !important;
}
.u-lts01em {
  letter-spacing: 0.1em !important;
}
.u-lts02em {
  letter-spacing: 0.2em !important;
}
.u-lts03em {
  letter-spacing: 0.3em !important;
}
.u-lts--001em {
  letter-spacing: -0.01em !important;
}
.u-lts--002em {
  letter-spacing: -0.02em !important;
}
.u-lts--01em {
  letter-spacing: -0.1em !important;
}
.u-lts--02em {
  letter-spacing: -0.2em !important;
}
.u-lts--03em {
  letter-spacing: -0.3em !important;
}

/* テキスト揃え
----------------------------------------------- */
.u-tal {
  text-align: left;
}

.u-tac {
  text-align: center;
}

.u-tar {
  text-align: right;
}

/*
  Alignment
--------------------------------- */
$text-align: (left, center, right) !default;

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .u-ta {
      @each $val in $text-align {
        &[data-text-align~="#{$device + '-' + $val}"] {
          text-align: $val;
        }
      }
    }
  }
}

/* ディスプレイ
----------------------------------------------- */
/*
  Display
--------------------------------- */
$display: (none, inline, block, inline-block) !default;

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .u-d {
      @each $val in $display {
        &[data-display~="#{$device + '-' + $val}"] {
          display: $val;
        }
      }
    }
  }
}

.u-d-i {
  display: inline !important;
}
.u-d-n {
  display: none !important;
}
.u-d-b {
  display: block !important;
}
.u-d-ib {
  display: inline-block !important;
}
.u-d-fx {
  display: flex !important;
}
.u-d-ifx {
  display: inline-flex !important;
}
.u-d-tb {
  display: table !important;
}
.u-d-itb {
  display: inline-table !important;
}
.u-d-tbhg {
  display: table-header-group !important;
}
.u-d-tbfg {
  display: table-footer-group !important;
}
.u-d-tbrg {
  display: table-row-group !important;
}
.u-d-tbr {
  display: table-row !important;
}
.u-d-tbc {
  display: table-cell !important;
}
.u-d-li {
  display: list-item !important;
}
.u-d-ih {
  display: inherit !important;
}

/* オーバーフロー
----------------------------------------------- */
.u-ov-v {
  overflow: visible !important;
}

.u-ov-h {
  overflow: hidden !important;
}

.u-ov-s {
  overflow: scroll !important;
}

.u-ov-a {
  overflow: auto !important;
}

.u-ovx-v {
  overflow-x: visible !important;
}

.u-ovx-h {
  overflow-x: hidden !important;
}

.u-ovx-s {
  overflow-x: scroll !important;
}

.u-ovx-a {
  overflow-x: auto !important;
}

.u-ovy-v {
  overflow-y: visible !important;
}

.u-ovy-h {
  overflow-y: hidden !important;
}

.u-ovy-s {
  overflow-y: scroll !important;
}

.u-ovy-a {
  overflow-y: auto !important;
}

/* clearfix
----------------------------------------------- */
.u-clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* 画像置換
----------------------------------------------- */
.u-hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

/* アクセシビリティ スクリーンリーダーテキスト
----------------------------------------------- */
.u-screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* フォント
----------------------------------------------- */
/* フォントウエイト
----------------------------------------------- */
.u-fw-nm {
  font-weight: 400 !important;
}

.u-fw-b {
  font-weight: 700 !important;
}

.u-fw-l {
  font-weight: 300 !important;
}

.u-fw-br {
  font-weight: bolder !important;
}

.u-fw-lr {
  font-weight: lighter !important;
}

.u-fw-100 {
  font-weight: 100 !important;
}

.u-fw-200 {
  font-weight: 200 !important;
}

.u-fw-300 {
  font-weight: 300 !important;
}

.u-fw-400 {
  font-weight: 400 !important;
}

.u-fw-500 {
  font-weight: 500 !important;
}

.u-fw-600 {
  font-weight: 600 !important;
}

.u-fw-700 {
  font-weight: 700 !important;
}

.u-fw-800 {
  font-weight: 800 !important;
}

.u-fw-900 {
  font-weight: 900 !important;
}

/* 文字サイズ
----------------------------------------------- */
@for $value from 10 through 50 {
  .u-fz-#{$value*1} {
    font-size: #{$value * 0.1}rem;
  }
}

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .u-fz {
      @for $i from 10 through 80 {
        &[data-font-size~="#{$device + '-' + $i}"] {
          font-size: 0.1rem * $i;
        }
      }
    }
  }
}

/* 文字サイズ em 拡大縮小
----------------------------------------------- */

// 変数の定義
$font-size: (
  "xxs": 0.4em,
  "xs": 0.6em,
  "sm": 0.8em,
  "md": 1.2em,
  "lg": 1.4em,
  "xl": 1.6em,
  "xxl": 1.8em,
);

@each $size, $value in $font-size {
  .u-fz-zoom-#{$size} {
    font-size: $value;
  }
}

/* 文字サイズ em 拡大縮小
----------------------------------------------- */
.u-tt-upper {
  text-transform: uppercase;
}

/* line-height
----------------------------------------------- */
.u-lh-xxs {
  line-height: 1.2;
}

.u-lh-xs {
  line-height: 1.4;
}

.u-lh-sm {
  line-height: 1.6;
}

.u-lh-md {
  line-height: 1.8;
}

.u-lh-lg {
  line-height: 2;
}

.u-lh-xl {
  line-height: 2.2;
}

.u-lh-xxl {
  line-height: 2.4;
}

/* vertical-align
----------------------------------------------- */

.u-va-sup {
  vertical-align: super !important;
}

.u-va-t {
  vertical-align: top !important;
}

.u-va-tt {
  vertical-align: text-top !important;
}

.u-va-m {
  vertical-align: middle !important;
}

.u-va-bl {
  vertical-align: baseline !important;
}

.u-va-b {
  vertical-align: bottom !important;
}

.u-va-tb {
  vertical-align: text-bottom !important;
}

.u-va-sub {
  vertical-align: sub !important;
}

/* テキスト　上付・下付文字
----------------------------------------------- */
.u-text-super {
  vertical-align: super;
}

.u-text-sub {
  vertical-align: sub;
}

/* 注釈
----------------------------------------------- */
.u-notes {
  margin-left: 1em;
  text-indent: -1em;
  &.-number {
    margin-left: 2.5em;
    text-indent: -2.5em;
  }
}

/* =================================================================

  マージン

================================================================= */

/* マージン上余白 */
@for $value from 1 through 20 {
  .u-mt-#{$value*5} {
    margin-top: #{$value * 0.5}rem !important;
  }
}

@for $value from 1 through 10 {
  .u-mt-#{$value*1}em {
    margin-top: #{$value * 1}em !important;
  }
}

// 変数の定義
// $mt-size: (
//   "xxs": 2.133333333333333vw,
//   "xs": 4.266666666666667vw,
//   "sm": 6.4vw,
//   "md": 8.533333333333333vw,
//   "lg": 10.666666666666667vw,
//   "xl": 12.266666666666667vw,
//   "xxl": 12.8vw,
// );
$mt-size: (
  "xxs": 2vw,
  "xs": 4vw,
  "sm": 7.5vw,
  "md": 10vw,
  "lg": 12.5vw,
  "xl": 15vw,
  "xxl": 18vw,
);

@each $size, $value in $mt-size {
  .u-mt-#{$size} {
    margin-top: $value;

    @include mq {
      margin-top: $value / 1.5;
    }
    @include mq {
      margin-top: $value / 1.5;
    }
    @include mq(xl) {
      margin-top: $value / 2;
    }
    @include mq(max) {
      margin-top: $value / 2.5;
    }
  }
}

/* マージン右余白 */
@for $value from 1 through 20 {
  .u-mr-#{$value*5} {
    margin-right: #{$value * 0.5}rem !important;
  }
}

@for $value from 1 through 10 {
  .u-mr-#{$value*1}em {
    margin-right: #{$value * 1}em !important;
  }
}

/* マージン下余白 */
@for $value from 1 through 20 {
  .u-mb-#{$value*5} {
    margin-bottom: #{$value * 0.5}rem !important;
  }
}

@for $value from 1 through 10 {
  .u-mb-#{$value*1}em {
    margin-bottom: #{$value * 1}em !important;
  }
}

@for $value from 1 through 10 {
  .u-mb-#{$value*1}vh {
    margin-bottom: #{$value * 1}vh !important;
  }
}

// 変数の定義
$mb-size: (
  "xxs": 2vw,
  "xs": 4vw,
  "sm": 7.5vw,
  "md": 10vw,
  "lg": 12.5vw,
  "xl": 15vw,
  "xxl": 18vw,
);

@each $size, $value in $mb-size {
  .u-mb-#{$size} {
    margin-bottom: $value;

    @include mq {
      margin-bottom: $value / 1.5;
    }
    @include mq(xl) {
      margin-bottom: $value / 2;
    }
    // @include mq(max) {
    //   margin-bottom: $value / 2.5;
    // }
  }
}

/* マージン左余白 */
@for $value from 1 through 20 {
  .u-ml-#{$value*5} {
    margin-left: #{$value * 0.5}rem !important;
  }
}

@for $value from 1 through 10 {
  .u-ml-#{$value*1}em {
    margin-left: #{$value * 1}em !important;
  }
}

/* =================================================================

  パディング

================================================================= */

/* パディング上余白 */
@for $value from 1 through 20 {
  .u-pt-#{$value*5} {
    padding-top: #{$value * 0.5}rem !important;
  }
}

/* パディング右余白 */
@for $value from 1 through 20 {
  .u-pr-#{$value*5} {
    padding-right: #{$value * 0.5}rem !important;
  }
}

/* パディング下余白 */
@for $value from 1 through 20 {
  .u-pb-#{$value*5} {
    padding-bottom: #{$value * 0.5}rem !important;
  }
}

/* パディング左余白 */
@for $value from 1 through 20 {
  .u-pl-#{$value*5} {
    padding-left: #{$value * 0.5}rem !important;
  }
}

// 変数の定義
$ptb-size: (
  "xxs": 1rem,
  "xs": 2rem,
  "sm": 3rem,
  "md": 4rem,
  "lg": 5rem,
  "xl": 6rem,
  "xxl": 7rem,
);

@each $size, $value in $ptb-size {
  .u-ptb-#{$size} {
    padding-top: $value;
    padding-bottom: $value;

    @include mq {
      padding-top: $value * 1.5;
      padding-bottom: $value * 1.5;
    }

    @include mq(lg) {
      padding-top: $value * 2;
      padding-bottom: $value * 2;
    }
  }
}

/* =================================================================

  横幅

================================================================= */

/* width
----------------------------------------------- */
@for $value from 1 through 200 {
  .u-w-#{$value*5} {
    width: #{$value * 5}px !important;
  }
}

@for $i from 1 through 100 {
  .u-w-#{$i}pct {
    width: #{percentage($i * 0.1)} !important;
  }
}
@for $value from 1 through 20 {
  .u-w-#{$value}em {
    width: #{$value}em !important;
  }
}

/* max-width
----------------------------------------------- */
@for $value from 1 through 200 {
  .u-max-w-#{$value*5} {
    max-width: #{$value * 5}px !important;
  }
}
/* max-height
----------------------------------------------- */
@for $value from 1 through 200 {
  .u-max-h-#{$value*5} {
    max-height: #{$value * 5}px !important;
  }
}
