@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [link]


================================================================================= */

.c-link-color {
  color: $color-primary !important;
  &:hover {
    color: $color-link !important;
  }
}
.c-link-underline {
  border-bottom: solid 1px currentColor;

  &:hover {
    border-bottom: solid 1px transparent;
  }
}
.c-link-fade {
  &:hover {
    opacity: 0.7;
  }
}

// .c-link-arrow {
//   display: inline-block;
//   position: relative;
//   margin-left: 2em;
//   transition: 0.3s linear;
//   border-bottom: solid 1px $color-primary;
//   color: $color-primary;
//   font-weight: bold;
//   line-height: 2;
//   &:hover {
//     border-bottom: solid 1px transparent;
//     color: $color-link;
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: -2em;
//     width: 20px;
//     height: 20px;
//     margin: auto 0;
//     transition: 0.3s linear;
//     border-radius: 5px;
//     background-color: $color-primary;
//   }
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: calc(-2em + 6px);
//     width: 6px;
//     height: 6px;
//     margin: auto 0;
//     transform: rotate(45deg);
//     transition: 0.3s linear;
//     border-top: 2px solid #fff;
//     border-right: 2px solid #fff;
//   }
//   &:hover {
//     &::before {
//       background-color: $color-link;
//     }
//   }
// }
