@charset "utf-8";

/* =================================================================================


  Component - コンポーネント [grid]


================================================================================= */

/*
  Settings
--------------------------------- */
// Grid Column
$column: 12;

// Gutter Size
$gutters: (
  "xxs": 0.25vw,
  "xs": 0.5vw,
  "sm": 1vw,
  "md": 2vw,
  "lg": 3vw,
  "xl": 4vw,
  "xxl": 5vw,
) !default;

/*
  Base
--------------------------------- */
.c-grid {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    min-height: 1%;
    img {
      flex-shrink: 0;
    }
    > * {
      min-height: 1%;
    }
  }
}

/*
  Direction
--------------------------------- */
.c-grid {
  &[data-dir="row"] {
    flex-direction: row;
  }

  &[data-dir="reverse"] {
    flex-direction: row-reverse;
  }

  &[data-dir="column"] {
    flex-direction: column;
  }

  &[data-dir="column-reverse"] {
    flex-direction: column-reverse;
  }
}

$direction: (row, row-reverse, column, column-reverse) !default;

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .c-grid {
      @each $val in $direction {
        &[data-dir~="#{$device + '-' + $val}"] {
          flex-direction: $val;
        }
      }
    }
  }
}

/*
  Alignment
--------------------------------- */

.c-grid {
  &[data-align~="start"] {
    justify-content: flex-start;
  }

  &[data-align~="center"] {
    justify-content: center;
  }

  &[data-align~="end"] {
    justify-content: flex-end;
  }

  &[data-align~="justify"] {
    justify-content: space-between;
  }

  &[data-align~="top"] {
    align-items: flex-start;
  }

  &[data-align~="middle"] {
    align-items: center;
  }

  &[data-align~="bottom"] {
    align-items: flex-end;
  }

  &__item {
    &[data-align~="start"] {
      align-items: flex-start;
    }

    &[data-align~="center"] {
      align-items: center;
    }

    &[data-align~="end"] {
      align-items: flex-end;
    }

    &[data-align~="top"] {
      align-self: stretch;
      justify-content: flex-start;
    }

    &[data-align~="middle"] {
      align-self: stretch;
      justify-content: center;
    }

    &[data-align~="bottom"] {
      align-self: stretch;
      justify-content: flex-end;
    }
  }
}

$justify-content: (
  start: flex-start,
  center: center,
  end: flex-end,
  justify: space-between,
) !default;

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .c-grid {
      @each $align, $val in $justify-content {
        &[data-justify-content~="#{$device + '-' + $align}"] {
          justify-content: $val;
        }
      }
    }
  }
}

$align-items: (
  top: flex-start,
  middle: center,
  bottom: flex-end,
) !default;

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .c-grid {
      @each $align, $val in $align-items {
        &[data-align-items~="#{$device + '-' + $align}"] {
          align-items: $val;
        }
      }
    }
  }
}

/*
  Grid
--------------------------------- */

.c-grid {
  &[data-gutter] {
    width: auto;
    // margin: -(4vw * 1 / 2); //spの場合は全て固定gutter

    & > .c-grid__item {
      border: solid transparent;
      // border-width: (4vw * 1 / 2); //spの場合は全て固定gutter
      border-width: 0;
      background-clip: padding-box;
    }
  }

  &[data-x-gutter] {
    width: auto;
    // margin-right: -(4vw * 1 / 2); //spの場合は全て固定gutter
    // margin-left: -(4vw * 1 / 2); //spの場合は全て固定gutter

    & > .c-grid__item {
      border: solid transparent;
      // border-width: (4vw * 1 / 2); //spの場合は全て固定gutter
      background-clip: padding-box;
    }
  }
}

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    .c-grid {
      @if $device== "xxs" {
        @each $size, $val in $gutters {
          &[data-gutter~="#{$device + '-' + $size}"] {
            margin: -($val);

            & > .c-grid__item {
              border-width: ($val);
            }
          }
        }

        @each $size, $val in $gutters {
          &[data-x-gutter~="#{$device + '-' + $size}"] {
            margin-right: -($val);
            margin-left: -($val);

            & > .c-grid__item {
              border-width: ($val);
            }
          }
        }
      } @else if $device== "md" {
        @each $size, $val in $gutters {
          &[data-gutter~="#{$device + '-' + $size}"] {
            margin: -($val / 2);

            & > .c-grid__item {
              border-width: ($val / 2);
            }
          }
        }

        @each $size, $val in $gutters {
          &[data-x-gutter~="#{$device + '-' + $size}"] {
            margin-right: -($val / 2);
            margin-left: -($val / 2);

            & > .c-grid__item {
              border-width: ($val / 2);
            }
          }
        }
      } @else if $device== "lg" {
        @each $size, $val in $gutters {
          &[data-gutter~="#{$device + '-' + $size}"] {
            margin: -($val / 2);

            & > .c-grid__item {
              border-width: ($val / 2);
            }
          }
        }

        @each $size, $val in $gutters {
          &[data-x-gutter~="#{$device + '-' + $size}"] {
            margin-right: -($val / 2);
            margin-left: -($val / 2);

            & > .c-grid__item {
              border-width: ($val / 2);
            }
          }
        }
      }

      &__item {
        @for $i from 1 through $column {
          &[data-grid~="#{$device + $i}"] {
            width: percentage($i / $column);
          }
        }

        &[data-grid~="#{$device}Five"] {
          width: 20%;
        }

        &[data-grid~="#{$device}Auto"] {
          width: auto;
        }

        @for $i from 0 through 10 {
          &[data-order~="#{$device + '-' + $i}"] {
            order: $i;
          }
        }
      }
    }
  }
}

@each $device, $breakpoint in $breakpoints {
  @media screen and (min-width: $breakpoint) {
    @for $i from 1 through $column {
      .grid-#{$i} {
        width: percentage($i / $column);
      }
    }
  }
}
