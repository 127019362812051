@charset "utf-8";
/* =================================================================================


  Layout - Spacer


================================================================================= */
.l-spacer {
  padding: 12vw 0;
  @include mq {
    padding: 6vw 0;
  }
  &.-xs {
    padding: 8vw 0;
    @include mq {
      padding: 2vw 0;
    }
  }
  &.-sm {
    padding: 10vw 0;
    @include mq {
      padding: 4vw 0;
    }
  }
  &.-lg {
    padding: 14vw 0;
    @include mq {
      padding: 8vw 0;
    }
  }
  &.-light-gray {
    background-color: $color-light-gray;
  }
  &.-light-beige {
    background-color: $color-light-beige;
  }
  &.-light-green {
    background-color: $color-light-green;
  }
  &.-dark-green {
    background-color: $color-dark-green;
  }
}
