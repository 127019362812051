@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [card]


================================================================================= */

.c-card {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  &__body {
    order: 1;
    width: 100%;
    min-height: 1%;
  }

  &__thumbnail {
    width: 100%;
    min-height: 1%;
    img {
      width: 100%;
    }
  }
}
