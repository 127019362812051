@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [list]


================================================================================= */

/* =================================================================
  説明リスト
================================================================= */

.c-definition-list {
  overflow: hidden;
  dt {
    width: 5em;
    float: left;
    clear: both;
  }
  dd {
    padding: 0 0 0 6em;
  }
}

/* =================================================================
  一般リスト
================================================================= */

.c-list-primary {
  li {
    position: relative;
    padding-left: 1em;
    &::before {
      content: "";
      position: absolute;
      top: 0.9em;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: currentColor;
      vertical-align: middle;
    }
    &.-horizontal {
      display: inline-block;
      margin-right: 1em;
    }
  }

  &.-purple {
    li {
      &::before {
        background: $color-purple;
      }
    }
  }
}

/* =================================================================
  番号つきリスト
================================================================= */

/*
  number
----------------------------------------------- */
.c-list-number {
  counter-reset: item;
  li {
    position: relative;
    padding-left: 1.3em;
    &::before {
      content: counter(item) ".";
      position: absolute;
      left: 0;
      counter-increment: item;
    }
  }
}

.c-list-inside {
  margin-left: 1.04em;
  list-style-position: inside;
  text-indent: -1.04em;
}
