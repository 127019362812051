@charset "utf-8";

/* =================================================================================


  Component - コンポーネント [テーブル]


================================================================================= */

/* =================================================================

  お問合わせ

================================================================= */

.c-table-contact {
  width: 100%;
  max-width: 850px;

  tr {
    display: block;
    padding: 1em 0 2em;
  }
  th,
  td {
    display: block;
    width: 100%;
    padding: 0;
    text-align: left;
  }
  th {
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 500;
  }
  td {
    padding: 0 2rem;
    font-size: 1.5rem;
    @include mq(sm) {
      font-size: 1.8rem;
    }
  }

  .required {
    position: relative;

    &::after {
      content: "必須";
      display: inline-block;
      position: relative;
      top: -2px;
      margin-left: 1rem;
      padding: 0.1rem 1em;
      border-radius: 6px;
      background: $color-secondary;
      color: #fff;
      font-size: 1.4rem;
      font-weight: normal;
      @include mq {
        position: absolute;
        top: auto;
        right: 0;
      }
    }
  }

  @include mq {
    tr {
      display: table-row;
      padding: 0;
    }
    th,
    td {
      display: table-cell;
      width: auto;
    }
    th {
      width: 11em;
      margin-bottom: 0;
      padding: 1em 0;
      font-size: 2.8rem;
      vertical-align: top;
      white-space: nowrap;
    }
    td {
      margin: 0;
      padding: 1em;
      font-size: 2.5rem;
    }
  }
}
