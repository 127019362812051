@charset "utf-8";

.l-hamburger-button {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  transition: 0.6s;
  transition-property: transform, opacity;
  transition-delay: 0.6s, 0.6s;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  pointer-events: all;
  appearance: none;

  &:focus {
    box-shadow: 0 0 5px 2px rgba($color-ascs, 0.3);
  }
}

/* 3本線
----------------------------------------------- */
.l-hamburger-icon {
  display: block;
  position: relative;
  transition: 0.6s;
}

.l-hamburger-icon,
.l-hamburger-icon:before,
.l-hamburger-icon:after {
  width: 100%;
  height: 2px;
  -webkit-transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 99rem;
  background-color: #fff;

  // .is-change & {
  //   background-color: $color-default;
  // }
  .is-ascs & {
    background-color: $color-default;
  }
}

.l-hamburger-icon:before,
.l-hamburger-icon:after {
  content: "";
  position: absolute;
  right: 0;
}

.l-hamburger-icon::before {
  top: 10px;
}

.l-hamburger-icon:after {
  top: -10px;
}

/* classにopenが付与されたとき
----------------------------------------------- */
// .is-drawer-open .l-hamburger-button {
//   background-color: $color-default;
// }

.is-drawerActive .l-hamburger-icon {
  background-color: transparent;
}

.is-drawerActive .l-hamburger-icon:before,
.is-drawerActive .l-hamburger-icon:after {
  top: 0;
  background-color: #fff;
}

.is-drawerActive .l-hamburger-icon:before {
  transform: rotate(45deg);
}

.is-drawerActive .l-hamburger-icon:after {
  width: 100%;
  transform: rotate(-45deg);
}

// .is-animation {
//   .is-open.l-hamburger-icon:before,
//   .is-open.l-hamburger-icon:after {
//     background-color: #000;
//   }
// }
