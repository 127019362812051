@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [ボタン]


================================================================================= */

/*
  Primary
----------------------------------------------- */

.c-button-primary {
  display: inline-block;
  position: relative;
  width: 360px;
  max-width: 100%;
  padding: 0.5em 1.5em;
  font-size: 2.1rem;
  font-weight: 100;
  text-align: center;
  clip-path: polygon(40px 0, 100% 0, 100% 100%, 0 100%);
  @include mq {
    width: 742px;
    padding: 0.1em 3em 0.1em 1em;
    font-size: 4rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.3s ease;
    transition-property: transform;
    background: #000;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 12%;
    bottom: 0;
    width: 0.6em;
    height: 0.6em;
    margin: auto;
    transform: rotate(45deg);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  &.-ascs {
    background-color: #73aa17;
    color: #fff;
  }
  &.-drl {
    background-color: #01e8e8;
  }
  span.text {
    position: relative;
    width: 100%;
  }
  span.arrow {
    display: inline-block;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 25%;
      height: 100%;
      background-color: #000;
    }
  }

  &:hover {
    color: #fff;

    &::before {
      transform: scaleX(1);
      transform-origin: right;
    }
  }
}

/*
  Secondary
----------------------------------------------- */

.c-button-secondary {
  display: inline-block;
  position: relative;
  width: 583px;
  max-width: 100%;
  padding: 0.2em 1.5em;
  border: 8px solid currentColor;
  background-color: #fff;
  font-size: 3rem;
  font-weight: 100;
  letter-spacing: 0.15em;
  text-align: center;
  text-transform: uppercase;
  @include mq {
    padding: 0.1em 1em;
    font-size: 4rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.3s ease;
    transition-property: transform;
    background: currentColor;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 1em;
    bottom: 0;
    width: 0.6em;
    height: 0.6em;
    margin: auto;
    transform: rotate(45deg);
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
  }

  span {
    position: relative;
  }

  &.-ascs {
    color: $color-ascs;
    &::before {
      background: $color-ascs;
    }
  }
  &.-drl {
    color: $color-drl;
    &::before {
      background: $color-drl;
    }
  }

  &:hover {
    position: relative;
    color: #fff;
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

/*
  View All
--------------------------------- */
.c-button-viewAll {
  display: inline-block;
  padding-bottom: 0.2em;
  border-bottom: 2px solid currentColor;
  // font-weight: bold;
  font-size: 1.6rem;
  &:hover {
    border-bottom: 2px solid transparent;
  }
  @include mq {
    font-size: 2rem;
  }
}

/* =================================================================
  ページのトップに戻るボタン
================================================================= */

.c-button-page-scroll {
  display: flex;

  /* ボタンの装飾 */
  position: fixed;
  z-index: 10;
  right: 5%;
  bottom: 30px;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 0;
  transition: opacity 0.3s, transform 0.3s ease;
  /* buttonタグのリセットCSS */
  border: none;
  border-radius: 0;
  outline: none;
  opacity: 0;
  background-color: $color-default;
  cursor: pointer;
  appearance: none;
  @include mq {
    width: 50px;
    height: 50px;
  }
}

/* 矢印のスタイル */
.c-button-page-scroll::before {
  content: "";
  width: 12px;
  height: 12px;
  margin-bottom: -6px;
  transform: rotate(-45deg);
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
}

/* ホバー時のスタイル */
.c-button-page-scroll:hover {
  transform: scale(1.1);
}

/* 郵便番号 検索ボタン
----------------------------------------------- */
.c-button-zip-search {
  margin: 0 0 0 1em;
  padding: 6px 1em;
  transition: 0.3s linear;
  border: 0;
  border-radius: 3px;
  background: #333;
  color: #fff;
  font-size: 1.4rem;
  cursor: pointer;
  -webkit-appearance: none;

  &:hover {
    background: $color-primary;
  }
}

/* 送信・確認ボタン
----------------------------------------------- */

.c-button-submit-wrap {
  display: inline-block;
  position: relative;
  width: 583px;
  max-width: 100%;
  padding: 0.2em 1em;
  transition: 0.5s;
  border: 1px solid #000;
  background-color: #333;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: right;
    transition: all 0.3s ease;
    transition-property: transform;
    background: #000;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 1.5em;
    bottom: 0;
    width: 0.6em;
    height: 0.6em;
    margin: auto;
    transform: rotate(45deg);
    transition: 0.3s;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    font-size: 2rem;
    @include mq {
      font-size: 4.5rem;
    }
  }
  &:hover {
    color: #fff;

    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}

.c-button-submit {
  position: relative;
  width: 100%;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  font-size: 4rem;
  font-weight: 100;
  cursor: pointer;
  // pointer-events: none;
  -webkit-appearance: none;

  @include mq {
    font-size: 4.5rem;
  }

  // &.-send {
  //   background-color: #000;
  // }

  &.-back {
    background: #666;
    pointer-events: auto;
  }

  // &.is-disabled {
  //   pointer-events: none;
  //   background-color: #999;
  // }

  // &.is-enabled {
  //   background-color: $color-secondary;
  //   pointer-events: auto;
  // }
}
