@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [inview]


================================================================================= */

.c-inview {
  // -webkit-backface-visibility: hidden;
  // backface-visibility: hidden;
  perspective: 1000;
  transition-duration: 1s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);

  &.is-inviewActive {
    transition-property: opacity, transform, letter-spacing, filter;
  }

  &[data-delay~="d0_3s"] {
    transition-delay: 0.3s;
  }
  &[data-delay~="d0_5s"] {
    transition-delay: 0.5s;
  }
  &[data-delay~="d1s"] {
    transition-delay: 1s;
  }
  &[data-delay~="d1_5s"] {
    transition-delay: 1.5s;
  }
  &[data-delay~="d2s"] {
    transition-delay: 2s;
  }

  &[data-type~="fadeIn"] {
    transition-duration: 2s;
    opacity: 0.0001;
    &.is-inviewActive {
      opacity: 1;
    }
  }

  &[data-type~="fadeUp"] {
    position: relative;
    transform: translate3d(0, 20px, 0) skewY(3deg);
    transform-origin: left;
    opacity: 0.0001;
    &.is-inviewActive {
      transform: translate3d(0, 0, 0) skewY(0);
      opacity: 1;
    }
  }

  &[data-type~="slideUp"] {
    transform: translate3d(0, 30px, 0) skewY(0);
    opacity: 0.0001;
    &.is-inviewActive {
      transform: translate3d(0, 0, 0) skewY(0);
      opacity: 1;
    }
  }

  &[data-type~="slideLeft"] {
    transform: translateX(-180px);
    opacity: 0.0001;
    &.is-inviewActive {
      transform: translateX(0);
      opacity: 1;
    }
  }
  &[data-type~="slideRight"] {
    transform: translateX(180px);
    opacity: 0.0001;
    &.is-inviewActive {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &[data-type~="titleLeft"] {
    span.text {
      transform: translateX(-180px);
      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-delay: 1s;
      opacity: 0.0001;
    }
    span.border {
      &::after {
        right: 100vw;
        perspective: 1000;
        transition-duration: 0.5s;
        transition-delay: 0.5s;
        opacity: 0.001;
      }
    }
    &.is-inviewActive {
      span.text {
        transform: translateX(0);
        opacity: 1;
      }
      span.border {
        &::after {
          right: 0;
          opacity: 1;
        }
      }
    }
  }

  &[data-type~="titleRight"] {
    span.text {
      transform: translateX(180px);
      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-delay: 1s;
      opacity: 0.0001;
    }
    span.border {
      &::after {
        left: 100vw;
        perspective: 1000;
        transition-duration: 0.5s;
        transition-delay: 0.5s;
        opacity: 0.001;
      }
    }
    &.is-inviewActive {
      span.text {
        transform: translateX(0);
        opacity: 1;
      }
      span.border {
        &::after {
          left: 0;
          opacity: 1;
        }
      }
    }
  }

  &[data-type~="zoomIn"] {
    transform: scale(1.1);
    transition-duration: 3s;
    animation-timing-function: cubic-bezier(0.25, 0.6, 0.35, 1);
    &.is-inviewActive {
      transform: scale(1);
    }
  }
}
