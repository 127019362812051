@charset "utf-8";

/* =================================================================================


  Project - slider.scss


================================================================================= */

.p-slider {
  position: absolute;
  // z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  .swiper-wrapper {
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @keyframes zoomUp {
    0% {
      transform: scale(1.01);
    }
    100% {
      transform: scale(1);
    }
  }

  .swiper-slide-active img.hero,
  .swiper-slide-duplicate-active img.hero,
  .swiper-slide-prev img.hero {
    animation: zoomUp 8s ease 0s 1 normal both;
  }

  //pagination
  .swiper-pagination {
    left: 0;
    width: 100%;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 40px;
    height: 4px;
    margin: 1rem;
    transition: 0.3s linear;
    border-radius: 0;
    opacity: 0.3;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #dc0017;
  }
}
