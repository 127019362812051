@charset "utf-8";

.l-main {
  position: relative;
  z-index: 10;
  margin-top: 100vh;
  margin-bottom: -1px;
  padding-top: 16vw;
  clip-path: polygon(0 calc(0% + 16vw), 100% 0, 100% 100%, 0 100%);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(-9.16deg, #e8e8e8 0, #e8e8e8 1px, #fff 0, #fff 7px);
  }
}
