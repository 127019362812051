@charset "utf-8";
/* =================================================================================


  Layout - Footer


================================================================================= */
.l-footer {
  position: relative;
  padding: 5rem 5% 10rem;
  background-color: #fff;
  text-align: center;
  @include mq {
    padding: 15rem 5%;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    @include mq {
      height: 50%;
    }
  }

  &::before {
    background: rgb(1, 255, 255, 1);
    background: linear-gradient(270deg, rgba(1, 255, 255, 1) 40%, rgba(1, 128, 128, 1) 100%);
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
  }

  &::after {
    background: rgb(115, 170, 23);
    background: linear-gradient(90deg, rgba(115, 170, 23, 1) 60%, rgba(83, 126, 30, 1) 100%);
    clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  }

  &__copyright {
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 300;
    @include mq {
      font-size: 2rem;
    }
  }
  &__link {
    position: relative;
    z-index: 1;
    font-size: 1.8rem;
    font-weight: 300;
    @include mq {
      font-size: 2rem;
    }
    a {
      border-bottom: solid 1px currentColor;
      &:hover {
        border-bottom: solid 1px transparent;
      }
    }
  }
}
