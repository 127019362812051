@charset "utf-8";
/* =================================================================================


  color.scss


================================================================================= */

/* サイトで全体で使うカラーコードの変数を指定 */

$color-default: #000; //デフォルトのテキストカラー
$color-primary: #740b38; //プライマリ
$color-secondary: #6f7765; //セカンダリ
$color-tertiary: #4b5045; //ターシャリ
$color-quaternary: #fbf6eb; //クォータナリ
$color-quinary: #d8171b; //クワイナリ

$color-link: #740b38;
$color-required: #c86464;

$color-ascs: #73aa17;
$color-drl: #01ffff;

/* 色指定 */
$color-white: #fff;
$color-red: #d8171b;
$color-blue: #427dc8;
$color-orange: #f09646;
$color-purple: #740b38;
$color-gray: #767676;
$color-light-gray: #f8f8f8;
$color-light-beige: #dddbd0;
$color-light-green: #e1e3dd;
$color-dark-green: #4b5045;

/* ソーシャル系 */
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-line: #00b900;
$color-pocket: #ee4056;
$color-google-plus: #db4437;
$color-youtube: #e62117;
$color-pdf: #da251c;
