@charset "utf-8";

/*
  Settings
--------------------------------- */

// Breakpoints
$breakpoints: (
  xxs: 0,
  xs: 375px,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1680px,
  xxxl: 1920px,
) !default;

/*
  Media Queries
--------------------------------- */

@mixin mq($breakpoint: md, $rule: min, $subtraction: true) {
  $breakpoint: map_get($breakpoints, $breakpoint);

  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint;
  } @else if $rule==min and $subtraction {
    // $breakpoint: $breakpoint + 1px;
    $breakpoint: $breakpoint;
  }

  @media print, screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

/*
  wrap
--------------------------------- */
@mixin wrap-xxl($value: 1920px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

@mixin wrap-xl($value: 1400px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

@mixin wrap-lg($value: 1280px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

@mixin wrap-md($value: 1200px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

@mixin wrap-sm($value: 960px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

@mixin wrap-xs($value: 728px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

@mixin wrap-xxs($value: 480px) {
  max-width: $value;
  margin-right: auto;
  margin-left: auto;
}

/* 上マージン
----------------------------------------------- */
// 変数の定義
$mt-sizes: (
  "xxs": 1rem,
  "xs": 2rem,
  "sm": 3rem,
  "md": 4rem,
  "lg": 5rem,
  "xl": 6rem,
  "xxl": 7rem,
);

@mixin mt($size: md) {
  $size: map_get($mt-sizes, $size);
  margin-top: $size;

  @include mq {
    margin-top: $size * 1.25;
  }

  @include mq(lg) {
    margin-top: $size * 1.5;
  }
}

/* 下マージン
----------------------------------------------- */
// 変数の定義
$mb-sizes: (
  "xxs": 1rem,
  "xs": 2rem,
  "sm": 3rem,
  "md": 4rem,
  "lg": 5rem,
  "xl": 6rem,
  "xxl": 7rem,
);

@mixin mb($size: md) {
  $size: map_get($mb-sizes, $size);
  margin-bottom: $size;

  @include mq {
    margin-bottom: $size * 1.25;
  }

  @include mq(lg) {
    margin-bottom: $size * 1.5;
  }
}

/* 上下余白
----------------------------------------------- */
// 変数の定義
$ptb-sizes: (
  "xxs": 1rem,
  "xs": 2rem,
  "sm": 3rem,
  "md": 4rem,
  "lg": 5rem,
  "xl": 6rem,
  "xxl": 7rem,
);

@mixin ptb($size: md) {
  $size: map_get($ptb-sizes, $size);
  padding-top: $size;
  padding-bottom: $size;

  @include mq {
    padding-top: $size * 1.5;
    padding-bottom: $size * 1.5;
  }

  @include mq(lg) {
    padding-top: $size * 2;
    padding-bottom: $size * 2;
  }
}

/*
  grid
----------------------------------------------- */
@mixin grid {
  display: flex;
  flex-wrap: wrap;
}

@mixin grid-justify {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin grid-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
