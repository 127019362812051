@charset "utf-8";

//一文字ずつフェードイン
@keyframes letter-fadeIn {
  0% {
    // display: inline-block;
    // backface-visibility: hidden;
    opacity: 0;
    // color: transparent;
    // will-change: transform, opacity;
  }

  100% {
    // transform: translate3d(0, 0, 0) rotate(0) skewX(0) scale(1);
    opacity: 1;
  }
}

//一文字ずつズームイン
@keyframes letter-zoomIn {
  0% {
    display: inline-block;
    transform: translate3d(-7rem, 4rem, 0) rotate(-20deg) skewX(35deg) scale(1.5);

    backface-visibility: hidden;
    transition: transform 1.35s, opacity 1.35s, text-shadow 1.35s;
    // text-shadow: 0 0 30px $color-default;
    opacity: 0;
    color: transparent;
    text-shadow: 0 0 8px #000;
    will-change: transform, opacity, text-shadow;
  }

  // 50% {
  //   color: transparent;
  // }
  100% {
    transform: translate3d(0, 0, 0) rotate(0) skewX(0) scale(1);
    opacity: 1;
    text-shadow: 0 0 0 #000;
  }
}

//ヘッダースライドフェードイン
@keyframes header-slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

//右からスライドフェードイン
@keyframes slideInRight {
  from {
    transform: translateX(70%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideInRight {
  animation-name: slideInRight;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

//下からスライドフェードイン
@keyframes slideInBottom {
  from {
    transform: translateY(70%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInBottom {
  animation-name: slideInBottom;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

//左から右に現れるCSSアニメーション
@keyframes inview-slide-body {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
  }

  50% {
    left: 0;
    width: 100%;
    opacity: 0.5;
  }

  51% {
    left: 0;
    width: 100%;
    opacity: 1;
  }

  100% {
    left: 100%;
    width: 0;
  }
}

@keyframes inview-slide-content {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//iTyped.js タイプライター風のテキストアニメーション
.ityped-cursor {
  -webkit-animation: blink 0.3s infinite;
  -moz-animation: blink 0.3s infinite;
  animation: blink 0.3s infinite;
  animation-direction: alternate;
  opacity: 1;
  font-size: 1em;
}

@keyframes blink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  100% {
    opacity: 0;
  }
}
