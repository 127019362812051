@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [iframe]


================================================================================= */

.c-iframe {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  // @include mq {
  //   height: 400px;
  //   padding-bottom: 0;
  // }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
