@charset "utf-8";

/* =================================================================================


  base.scss


================================================================================= */
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  // visibility: hidden;
  min-height: 100%;
  // overflow-y: scroll;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  // scroll-behavior: smooth;
}

// html.wf-active,
// html.loading-delay {
//   visibility: visible;
// }

body,
input,
select,
textarea {
  color: $color-default;
  font-family: $font-gothic;
  font-size: 1.4rem;
  line-height: 2;
  -webkit-text-size-adjust: 100%;
  @include mq {
    font-size: 1.6rem;
  }
}

body {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: repeating-linear-gradient(-9.16deg, #e8e8e8 0, #e8e8e8 1px, #fff 0, #fff 7px);
  // }
}

/*
  リンク設定
--------------------------------- */
a {
  transition: 0.3s linear;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

/*
  フルードイメージ
--------------------------------- */
img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

/*
  アクセシビリティ スクリーンリーダーテキスト(WP用)
--------------------------------- */
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
