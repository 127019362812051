@charset "utf-8";

.l-container {
  @include wrap-md;
  box-sizing: content-box;

  &.-xxs {
    @include wrap-xxs;
  }
  &.-xs {
    @include wrap-xs;
  }
  &.-sm {
    @include wrap-sm;
  }
  &.-md {
    @include wrap-md;
  }
  &.-lg {
    @include wrap-lg;
  }
  &.-xl {
    @include wrap-xl;
  }
  &.-xxl {
    @include wrap-xxl;
  }
  &.-auto {
    max-width: none;
  }
  &.-gap {
    padding-right: 5%;
    padding-left: 5%;
  }
}
