@charset "utf-8";
/* =================================================================================


  Project - grid.scss


================================================================================= */

.p-grid-primary {
  &__item {
    width: 100%;
    min-height: 1%;
    @include mq {
      width: 45.625%;
    }

    // &:nth-child(even) {
    //   margin-bottom: 7rem;
    //   margin-left: auto;
    //   @include mq {
    //     margin-bottom: 0;
    //     margin-left: 0;
    //   }
    // }

    img {
      width: 100%;
    }
  }
}
