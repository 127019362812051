@charset "utf-8";

/* =================================================================================


  Component - コンポーネント [フォーム]


================================================================================= */

/* テキストエリア
----------------------------------------------- */

.c-form {
  input::placeholder,
  textarea::placeholder {
    color: #ccc;
  }

  /* IE */
  input:-ms-input-placeholder {
    color: #ccc;
  }

  /* Edge */
  input::-ms-input-placeholder {
    color: #ccc;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 0.8em 1em;
    border: solid 1px #ccc;
    border-radius: 0;
    background: #fff;
    -webkit-appearance: none;
  }
  textarea {
    height: 10em;
  }
  input[type="radio"],
  input[type="checkbox"] {
    vertical-align: baseline;
  }

  .mwform-zip-field {
    input[type="text"] {
      width: 6em;
    }
  }

  &__select {
    display: inline-block;
    position: relative;

    select {
      // width: 100%;
      position: relative;
      padding: 0.8em 3em 0.8em 1em;
      border: solid 1px #ccc;
      border-radius: 6px;
      -webkit-appearance: none;
      background: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 24px;
      right: 20px;
      border: 8px solid transparent;
      border-top: 10px solid #333;
      pointer-events: none;
      /* ▼ をクリックできるように */
    }
  }
}
.mwform-radio-field {
  display: inline-block;
  padding: 0.2em 0;
}
.mwform-radio-field-text {
  margin-right: 0.1em;
}

/* =================================================================

  エラー出力

================================================================= */

.mw_wp_form {
  .is-error {
    display: none;
  }

  &.mw_wp_form_error {
    .is-error {
      display: block;
      color: $color-red;
    }
  }
}

/* =================================================================

  確認画面

================================================================= */

.mw_wp_form_confirm {
  .confirm-none {
    display: none;
  }

  .c-form__select {
    &::after {
      display: none;
    }
  }
}

// label {
//   display: inline-block;
//   margin-right: 10px;
//   cursor: pointer;
// }

// input[type="radio"],
// input[type="checkbox"] {
//   // filter: alpha(opacity=0);
//   -moz-opacity: 0;
//   opacity: 0;
//   -webkit-appearance: none;
//   appearance: none;
//   position: absolute;
// }

/* セレクトボタン
----------------------------------------------- */

// .select {
//   display: inline-block;
//   position: relative;

//   // @include mq(md,max) {
//   //   width: 100%;
//   // }
//   select {
//     // width: 100%;
//     position: relative;
//     padding: 0.8em 3em 0.8em 1em;
//     border: solid 1px #ccc;
//     border-radius: 6px;
//     -webkit-appearance: none;
//     background: none;
//   }

//   &::after {
//     position: absolute;
//     content: "";
//     right: 20px;
//     top: 24px;
//     border: 8px solid transparent;
//     border-top: 10px solid #333;
//     pointer-events: none;
//     /* ▼ をクリックできるように */
//   }
// }

// .mw_wp_form_confirm {
//   .select {
//     &::after {
//       display: none;
//     }
//   }
// }

/* ラジオボタン
----------------------------------------------- */

// input[type="radio"] {
//   & + .radio-icon {
//     vertical-align: middle;
//     display: inline-block;
//     width: 15px;
//     height: 15px;
//     margin-right: 4px;
//     border: 1px solid #979797;
//     border-radius: 50%;
//     box-sizing: border-box;
//     margin-top: -3px;
//     background: #ffffff;
//   }

//   &:checked + .radio-icon {
//     position: relative;
//     content: "";
//     border: 1px solid $color-primary;
//     background: $color-primary;
//     box-shadow: 0 0 0 3px #ffffff inset;
//   }

//   &:focus {
//     & + .radio-icon {
//       border: 1px solid $color-primary;
//       box-shadow: 0 0 4px $color-primary;
//       box-shadow: 0 0 0 3px #ffffff inset, 0 0 4px $color-primary;
//     }
//   }
// }

/* チェックボックス
----------------------------------------------- */

// .checkbox {
//   .check-icon {
//     vertical-align: middle;
//     display: inline-block;
//     width: 18px;
//     height: 18px;
//     border: 1px solid #999;
//     border-radius: 2px;
//     box-sizing: border-box;
//     margin-top: -3px;
//     margin-right: 5px;
//     background-color: #fff;
//     cursor: pointer;
//   }

//   input[type="checkbox"] {
//     &:checked + .check-icon {
//       position: relative;
//       // background: $color-primary;
//       border: 1px solid $color-primary;

//       &:after {
//         content: "";
//         position: absolute;
//         display: inline-block;
//         top: -7px;
//         left: 5px;
//         width: 10px;
//         height: 20px;
//         transform: rotate(40deg);
//         border-bottom: 3px solid $color-primary;
//         border-right: 3px solid $color-primary;
//       }
//     }

//     &:focus {
//       & + .check-icon {
//         border: 1px solid #999;
//         box-shadow: 0 0 4px #004aa1, 0 0 8px #004aa1;
//       }
//     }
//   }
// }
