@charset "utf-8";
/* =================================================================================


  Project - hero.scss


================================================================================= */

/*
  Home
--------------------------------- */
.p-hero {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &__catch {
    position: absolute;
    z-index: 100;
    top: 45%;
    left: 52%;
    width: 90%;
    transform: translate(-50%, -50%);
    clip-path: inset(0 100% 0 0);
    transition: 0.5s cubic-bezier(0.37, 0, 0.63, 1);
    text-align: center;

    @include mq {
      top: 50%;
      left: 50%;
      width: 62.5%;
    }
    &.is-loaded {
      clip-path: inset(0);
      transition: 0.5s cubic-bezier(0.37, 0, 0.63, 1);
      transition-property: clip-path;
    }
  }

  &__stripe {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/common/stripe.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__scrolldown {
    position: absolute;
    z-index: 20;
    right: 0;
    bottom: 160px;
    left: 0;
    width: 100%;
    margin: 0 auto;
    line-height: 1;
    text-align: center;
    @include mq {
      bottom: 90px;
    }
  }

  &__scrolldown span {
    position: relative;
    // position: absolute;
    // top: 0;
    // left: 0;
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    // -ms-writing-mode: tb-rl;
    // -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
  }

  &__scrolldown::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 76px;
    margin: 0 auto;
    animation: pathmove 2.5s cubic-bezier(1, 0, 0.6, 1) infinite;
    opacity: 0;
    background: #eee;
  }

  /*高さ・位置・透過が変化して線が上から下に動く*/
  @keyframes pathmove {
    0% {
      bottom: -10px;
      height: 0;
      opacity: 0;
    }
    30% {
      bottom: -86px;
      height: 76px;
      opacity: 1;
    }
    100% {
      bottom: -106px;
      height: 0;
      opacity: 0;
    }
  }

  /*高さ・位置・透過が変化して線が上から下に動く*/
  // @keyframes pathmove {
  //   0% {
  //     top: 80px;
  //     height: 0;
  //     opacity: 0;
  //   }
  //   30% {
  //     height: 30px;
  //     opacity: 1;
  //   }
  //   100% {
  //     top: 130px;
  //     height: 0;
  //     opacity: 0;
  //   }
  // }
}
