@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [タイトル]


================================================================================= */

/*
  Primary
----------------------------------------------- */
.c-title-primary {
  position: relative;
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 1.6;
  @include mq {
    font-size: 4rem;
  }
  @include mq(lg) {
    font-size: 5rem;
  }
  @include mq(xl) {
    font-size: 7rem;
    line-height: 1.4;
  }

  &.-black {
    font-weight: 900;
  }
  &.-lihgt {
    font-weight: 300;
  }

  &.-sm {
    font-size: 2.2rem;
    @include mq {
      font-size: 3rem;
    }
    @include mq(lg) {
      font-size: 3.6rem;
    }
    @include mq(xl) {
      font-size: 5rem;
    }
  }

  // アニメーション

  &__row {
    display: block;
    &.-pc-inline {
      @include mq {
        display: inline;
      }
    }
    .wrap {
      position: relative;
    }
    .text {
      display: inline-block;
    }
    .border {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100vw;
        height: 1px;
        background-color: currentColor;
      }
    }
  }

  // .border-left {
  //   display: inline-block;
  //   position: relative;
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //     width: 100vw;
  //     height: 1px;
  //     background-color: currentColor;
  //   }
  // }
  .border-right {
    display: inline-block;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 1px;
      background-color: currentColor;
    }
  }
}

/*
  Secondary
----------------------------------------------- */
.c-title-secondary {
  padding-bottom: 0.5em;
  border-bottom: 1px solid $color-gray;
  font-size: 1.8rem;
  font-weight: bold;
  @include mq {
    font-size: 2.2rem;
  }
  @include mq(lg) {
    padding-bottom: 0.8em;
    font-size: 2.6rem;
  }
  &.-primary {
    border-bottom: 1px solid currentColor;
    color: $color-primary;
  }
}

/*
  Tertiary
----------------------------------------------- */
.c-title-tertiary {
  order: 2;
  margin-top: 2rem;
  font-size: 3rem;
  letter-spacing: 0.08em;
  text-align: center;
  @include mq {
    margin-top: 0;
    font-size: 5rem;
    text-align: left;
  }
  span.jp {
    display: block;
    position: relative;
    font-size: 1.6rem;
    vertical-align: middle;
    @include mq {
      display: inline-block;
      padding-left: 2.5em;
      font-size: 2.2rem;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 1px;
        margin: auto;
        background-color: currentColor;
      }
    }
  }
}
