@charset "utf-8";
/* =================================================================================


  font.scss


================================================================================= */

// ゴシック
$font-gothic: "Noto Sans JP", "Yu Gothic", yugothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", meiryo, メイリオ, sans-serif;

// 明朝フォント
$font-serif: yumincho, "游明朝 DemiBold", "Yu Mincho DemiBold", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HG明朝E", "メイリオ", meiryo, serif;

// 英字フォント
$font-en: "Roboto", "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", meiryo, osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$font-pinyon-script: "Pinyon Script", cursive;
$font-caveat: "Caveat", cursive;
