@charset "utf-8";
/* =================================================================================


  Layout - Header


================================================================================= */

.l-header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  transition: top 0.6s, opacity 0.6s;
  transition-delay: 1s;
  opacity: 0.0001;
  background-color: transparent;

  @include mq {
    top: 2rem;
  }

  &.is-loaded {
    top: 0;
    opacity: 1;
  }

  .is-drawerActive & {
    z-index: 100;
  }
  &__logo {
    position: absolute;
    z-index: 99;
    top: 2.5rem;
    left: 2%;
    width: 50%;
    transition: top 0.6s;
    transition-delay: 0.6s;
    @include mq {
      top: 4rem;
      width: 20%;
    }
    .drl,
    .ascs {
      position: absolute;
      top: 2rem;
      left: 0;
      transition-property: top, opacity;
      transition-duration: 0.6s;
      opacity: 0.0001;
    }
    .is-drl & {
      .drl {
        top: 0;
        transition-delay: 1s;
        opacity: 1;
      }
    }
    .is-ascs & {
      .ascs {
        top: 0;
        transition-delay: 1s;
        opacity: 1;
      }
    }
  }

  // &__inner {
  //   display: flex;
  // }
  &__lead {
    position: relative;
    top: 38vw;
    transition: 0.6s;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6;
    text-align: center;

    @include mq(sm) {
      top: 30vw;
    }

    @include mq {
      top: 5rem;
      font-size: 2rem;
    }

    .drl,
    .ascs {
      display: block;
      position: absolute;
      top: 2rem;
      right: 0;
      left: 0;
      margin: 0 auto;
      transition-property: top, opacity;
      transition-duration: 0.6s;
      opacity: 0.0001;
    }

    .is-drl & {
      .drl {
        top: 0;
        transition-delay: 1s;
        opacity: 1;
      }
    }
    .is-ascs & {
      .ascs {
        top: 0;
        transition-delay: 1s;
        opacity: 1;
        color: $color-default;
      }
    }
  }

  &__button {
    position: absolute;
    z-index: 120;
    top: 2.5rem;
    right: 3%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    pointer-events: all;
    @include mq {
      top: 4.5rem;
    }

    .drl,
    .ascs {
      position: absolute;
      top: 2rem;
      transition: 0.6s;
      opacity: 0.0001;
    }

    .is-drl & {
      .drl {
        top: 0;
        transition-delay: 1s;
        opacity: 1;
      }
    }
    .is-ascs & {
      .ascs {
        top: 0;
        transition-delay: 1s;
        opacity: 1;
        color: $color-default;
      }
    }

    .is-drawerActive & {
      .drl,
      .ascs {
        top: 0;
        transition: none;
        opacity: 1;
      }
    }
  }
}
