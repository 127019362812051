@charset "utf-8";
/* =================================================================================


  Component - コンポーネント [color]


================================================================================= */

.c-color-default {
  color: $color-default;
}
.c-color-primary {
  color: $color-primary;
}
.c-color-secondary {
  color: $color-secondary;
}
.c-color-white {
  color: #fff;
}
.c-color-red {
  color: $color-red;
}
.c-color-blue {
  color: $color-blue;
}
.c-color-orange {
  color: $color-orange;
}
