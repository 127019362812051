@charset "utf-8";

/* =================================================================================


  Project / modal


================================================================================= */

.p-modal {
  .modaal-video-wrap {
    max-width: 1000px;
    margin: 0 auto;
  }
  .modaal-video-container {
    position: relative;
    // max-width: 100%;
    // max-width: 1300px;
    height: 0;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 56.25%;
    overflow: hidden;
    background: #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
