@charset "utf-8";
/* =================================================================================


  Project - home.scss


================================================================================= */

/*
  リード
--------------------------------- */
.p-lead {
  position: relative;
  padding: 10vw 0 70vw;
  overflow: hidden;
  text-align: right;
  @include mq {
    padding: 0 0 30vw;
  }

  &__title {
    margin-bottom: 1em;
    font-size: 2.8rem;
    font-weight: 900;
    @include mq {
      font-size: 4rem;
    }
  }
  &__text {
    position: relative;
    z-index: 1;
    font-size: 1.8rem;
    line-height: 2.5;
    @include mq {
      font-size: 2.8rem;
    }
  }
  &__image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
    @include mq {
      width: 66.71875%;
    }
  }
}

/*
  足利スクランブルシティスタジオ
--------------------------------- */
.p-ascs {
  margin-top: -20vw;
  overflow: hidden;
  &__head {
    position: relative;
    z-index: 1;
    padding: 24vw 0 100vw;
    color: #fff;
    clip-path: polygon(0 calc(0% + 16vw), 100% 0, 100% calc(100%), 0 100%);

    @include mq {
      padding: 24vw 0 10vw;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(0, 1);
      transform-origin: left top;
      transition: transform 0.5s;
      background: rgb(115, 170, 23);
      background: linear-gradient(90deg, rgba(115, 170, 23, 1) 60%, rgba(83, 126, 30, 1) 100%);
    }

    &.is-inviewActive {
      &::after {
        transform: scale(1, 1);
      }
    }
  }
  &__title {
    position: relative;
    z-index: 1;
  }
  &__text {
    position: relative;
    z-index: 1;
    font-size: 2rem;
    @include mq {
      font-size: 2.8rem;
    }
  }
  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 70%;
    @include mq {
      width: 43.75%;
    }
    img {
      width: 100%;
    }
  }
  &__body {
    position: relative;
    z-index: 1;
    margin-top: -16vw;
    padding-top: 16vw;
    padding-bottom: 16vw;
    @include mq {
      margin-top: -8.7vw;
      padding-bottom: 4vw;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      clip-path: polygon(0 calc(0% + 16vw), 100% 0, 100% 100%, 0 100%);
    }
  }
  &__content {
    margin-top: -10vw;
  }
  &__point {
    padding: 2em 1em 2em 0;
    font-size: 2rem;
    text-align: right;
    @include mq {
      margin-left: -2em;
      padding: 4em 2em 4em 0;
      font-size: 2.5rem;
    }
    li {
      position: relative;
      &::before {
        content: "・";
      }
      &:first-child {
        margin-bottom: 1em;
        @include mq {
          margin-bottom: 0.5em;
        }
      }
    }
  }
  &__gallery {
    li {
      width: 80%;
      @include mq {
        width: 100%;
      }
    }
    &.-right {
      margin-bottom: 10vw;
      @include mq {
        margin-bottom: 0;
      }
      li {
        margin-left: auto;
      }
    }
  }
}

/*
  デジタルリアリティロケーション
--------------------------------- */
.p-drl {
  padding-bottom: 16vw;
  overflow: hidden;
  background-color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 16vw), 0 100%);
  &__head {
    position: relative;
    z-index: 1;
    padding: 20vw 0 100vw;
    text-align: right;
    clip-path: polygon(0 0, 100% calc(0% + 16vw), 100% 100%, 0 100%);

    @include mq {
      padding: 20vw 0;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(0, 1);
      transform-origin: right top;
      transition: transform 0.5s;
      background: rgb(1, 255, 255, 1);
      background: linear-gradient(270deg, rgba(1, 255, 255, 1) 40%, rgba(1, 128, 128, 1) 100%);
    }
    &.is-inviewActive {
      &::after {
        transform: scale(1, 1);
      }
    }
  }
  &__title {
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  &__text {
    position: relative;
    z-index: 1;
    font-size: 2rem;
    @include mq {
      font-size: 2.8rem;
    }
  }
  &__image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    @include mq {
      width: 52%;
    }
    img {
      width: 100%;
    }
  }
  &__body {
    position: relative;
    z-index: 1;
    margin-top: -16vw;
    padding-top: 18vw;

    @include mq {
      margin-top: -8.7vw;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      clip-path: polygon(0 0, 100% calc(0% + 16vw), 100% 100%, 0 100%);
    }
  }
  &__content {
    margin-top: 0;
    @include mq {
      margin-top: -20vw;
    }
  }
  &__point {
    padding: 2em 0.5em;
    font-size: 2rem;
    @include mq {
      padding: 16vw 0 10vw 2em;
      font-size: 2.5rem;
    }
    li {
      position: relative;
      padding-left: 1em;
      &::before {
        content: "・";
        position: absolute;
        left: 0;
      }
    }
  }
  &__gallery {
    li {
      width: 80%;
      @include mq {
        width: 100%;
      }
    }
    &.-right {
      // margin-top: 10vw;
      // @include mq {
      //   margin-top: 0;
      // }
      li {
        margin-left: auto;
      }
    }
    &.-single {
      position: relative;
      margin-top: 10vw;
      @include mq {
        margin-top: 0;
      }
      @include mq(lg) {
        margin-top: -22%;
      }
      @include mq(xxl) {
        margin-top: -40%;
      }
    }
  }
}

/*
  利用事例
--------------------------------- */
.p-use {
  position: relative;
  z-index: 1;
  margin-bottom: 12vw;
  &__head {
    padding: 4vw 1%;
    text-align: center;
  }
  &__title {
    letter-spacing: -0.04em;
    span.border {
      @include mq {
        margin-bottom: 0.6em;
      }
    }
    span.sp-border {
      display: block;
      position: relative;
      margin-bottom: 0.5em;
      @include mq {
        display: inline;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 1px;
        margin: 0 calc(50% - 50vw);
        padding: 0 calc(50vw - 50% + 8px);
        background-color: #707070;
      }
      &.-ascs {
        &::after {
          background-color: $color-ascs;
        }
      }
      &.-drl {
        &::after {
          background-color: $color-drl;
        }
      }
    }
  }
  &__lead {
    font-size: 2rem;
    @include mq {
      font-size: 2.5rem;
    }
  }
  &__body {
    position: relative;
    padding: 30vw 0 0;
    overflow: hidden;
    @include mq {
      padding: 13.5vw 5% 6vw;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 76vw;
      background: $color-ascs;
      clip-path: polygon(0 calc(0% + 16vw), 100% 0, 100% calc(100% - 16vw), 0 100%);
      @include mq {
        height: 36vw;
      }
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 20%;
      left: 0;
      width: 100%;
      height: 76vw;
      background: $color-drl;
      clip-path: polygon(0 0, 100% calc(0% + 16vw), 100% 100%, 0 calc(100% - 16vw));
      @include mq {
        bottom: 0;
        height: 36vw;
      }
    }

    @include mq {
      .c-grid__item {
        &:nth-child(even) {
          transform: translateY(-6vw);
        }
        &:nth-child(odd) {
          transform: translateY(6vw);
        }
      }
    }
  }
  // &__thumbnail {
  //   position: relative;
  //   .play {
  //     display: inline-block;
  //     position: absolute;
  //     top: 50%;
  //     right: 0;
  //     left: 0;
  //     width: 50%;
  //     margin: 0 auto;
  //     transform: translateY(-50%);
  //     @include mq {
  //       width: 10vw;
  //     }
  //   }
  // }
}
.p-use-card {
  flex: 0 0 auto;

  &:hover {
    img.thumbnail {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }
  &__title {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.6;
    @include mq {
      font-size: 2rem;
    }
  }
  &__thumbnail {
    position: relative;
    overflow: hidden;
    background-color: #000;
    img.thumbnail {
      width: 100%;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: 0.5s cubic-bezier(0.25, 0.6, 0.35, 1);
    }
    img.play {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 28%;
      margin: 0 auto;
      transform: translateY(-50%);
      @include mq {
        width: 10vw;
      }
    }
  }
  &__body {
    &.-left {
      padding-left: 1em;
      @include mq {
        padding-left: 0;
      }
    }
  }
}
.p-use-item {
  width: 90%;
  margin-bottom: 4rem;
  overflow: hidden;
  &.-right {
    margin-left: auto;
  }
  @include mq {
    width: 100%;
  }
}

/*
  ご利用のご案内
--------------------------------- */
.p-guide {
  margin-bottom: 16vw;
}
.p-guide-ascs {
  position: relative;
  z-index: 1;
  padding: 20vw 0 8vw;
  color: #fff;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(115, 170, 23);
    background: linear-gradient(90deg, rgba(115, 170, 23, 1) 40%, rgba(83, 126, 30, 1) 100%);
    clip-path: polygon(0 calc(0% + 16vw), 100% 0, 100% calc(100% - 16vw), 0 100%);
  }
  &__list {
    display: flex;
    font-size: 1.8rem;
    @include mq {
      font-size: 2.8rem;
    }
  }
  &__dt {
    flex: 0 0 6.5em;
    font-weight: 500;
  }
  &__dd {
    font-weight: 300;
  }
  &__notes {
    padding-left: 6.5em;
    font-size: 1.8rem;
    font-weight: 300;
    @include mq {
      font-size: 2.8rem;
    }
    span {
      font-size: 1.5rem;
      @include mq {
        font-size: 2rem;
      }
    }
  }
}
.p-guide-city {
  position: relative;
  z-index: 1;
  margin-top: 4vw;
  @include mq {
    margin-top: -10vw;
  }
  &__image {
    width: 100%;
    @include mq {
      width: 63.515625%;
    }
  }
}

.p-guide-drl {
  position: relative;
  z-index: 2;
  margin-top: -16vw;
  padding: 20vw 0 8vw;
  overflow: hidden;
  font-size: 2rem;
  line-height: 1.6;
  text-align: right;
  @include mq {
    margin-top: -11vw;
    font-size: 2.5rem;
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(1, 255, 255, 1);
    background: linear-gradient(270deg, rgba(1, 255, 255, 1) 0, rgba(1, 160, 160, 1) 100%);
    clip-path: polygon(0 0, 100% calc(0% + 16vw), 100% 100%, 0 calc(100% - 16vw));
  }

  &__title {
    @include mq(xl) {
      font-size: 5rem;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-end;
  }
  &__dd {
    font-weight: 300;
  }
}

/*
  お問い合わせ
--------------------------------- */

.p-contact {
  position: relative;
  z-index: 1;
  &__title {
    margin-bottom: -1em;
  }
  &__form {
    position: relative;
    padding: 16vw 0 5vw;
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &::before {
      z-index: -1;
      height: 50%;
      background-color: #fff;
    }

    &::after {
      z-index: -1;
      height: 100%;
      background-color: #d9d9d9;
      clip-path: polygon(0 calc(0% + 16vw), 100% 0, 100% calc(100% - 16vw), 0 100%);
    }
  }
}
