@charset "utf-8";

/* clearfix
----------------------------------------------- */
%clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
