@charset "utf-8";

/* オーバーレイ */
.l-drawer {
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // overflow-y: auto;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  will-change: transform;
  /* scrollにはしないことでスクロールの必要がないときはスクロールバーを表示させない */
  -webkit-overflow-scrolling: touch;

  .is-drawerActive & {
    // transform: scale(1);
    opacity: 1;
    pointer-events: all;
    &__bg {
      transform: scale(1);
    }
    &__catch {
      clip-path: inset(0);
      transition: 0.5s cubic-bezier(0.37, 0, 0.63, 1);
      transition-property: clip-path;
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: scale(1.2);
    transition: 1s;
    background-image: url(../../assets/img/common/drawer-bg@2x.png);
    // background-image: url(../../assets/img/home/hero-ashikaga-scramble@2x.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__stripe {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/common/stripe.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__body {
    display: table;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: table-cell;
    padding: 0 5%;
    /* モーダル外側の余白 */
    vertical-align: middle;
  }

  &__catch {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 8%;
    left: 0;
    width: 80%;
    margin: auto;
    transition: none;
    clip-path: inset(0 100% 0 0);
    @include mq {
      right: 5%;
      bottom: 8%;
      left: auto;
      width: 34.375%;
    }
  }

  &__menu {
    // display: none;
    margin-top: -30vw;
    padding-left: 5%;
    transform: rotate(-9.16deg);
    font-size: 1.6rem;
    @include mq(xs) {
      font-size: 1.8rem;
    }
    @include mq(sm) {
      font-size: 2rem;
    }
    @include mq {
      margin-top: -8vw;
      font-size: 4rem;
    }
    > li {
      margin: 2em 0;
      @include mq {
        margin: 1.2em 0;
      }
      a {
        display: inline-block;
        position: relative;
        color: #fff;
        @include mq {
          font-weight: 100;
        }
        &::before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100vw;
          height: 1px;
          background-color: #fff;
        }
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
